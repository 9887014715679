import React, { useState, useEffect, useContext } from "react";
import { Appearance, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./CheckoutForm";
import "./CheckoutView.css";
import { CartOverview } from "./CartOverview";
import { Button, Spinner } from "react-bootstrap";
import { ShopContext } from "../Classes/ShopContext";
import { useNavigate } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_StripePK!);

export const CheckOutView : React.FC = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [isFree, setIsFree] = useState<boolean|undefined>(undefined);
  const apiUrl = process.env.REACT_APP_BackendApiBaseUrl + '/CreatePaymentIntent';
  
  const nav = useNavigate();
  const context = useContext(ShopContext);
  
  useEffect(() => {
    if(context === undefined || paymentId !== "") return;
    const { cart } = context;

    fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({productIds:cart.items.map(item => item.id)}),
      })
      .then((res) => res.json())
      .then((data) => {
        setIsFree(data.isFree)
        if(!data.IsFree){
          setClientSecret(data.clientSecret)
        }
        setPaymentId(data.paymentId)
      });
  }, [apiUrl, context?.cart.items, context, paymentId]);

  const onFreeDownload = () => {
    cart.clear()
    nav(`/downloads?paymentId=${paymentId}&free=true`)
  }

  const appearance : Appearance = {
    theme: 'stripe',
  };
  const options : StripeElementsOptions  = {
    clientSecret,
    appearance,
  };

  if(context === undefined) return <h1>Error :/</h1>
  const { user, cart } = context;

  if(isFree === undefined) return <Spinner/>;

  if(isFree) return (
    <div className="free-container">
      <p>Sie haben nur kostenlose Elemente im Warenkorb und können diese direkt herunterladen 😊!</p>
      <Button style={{width:250}} onClick={onFreeDownload} variant="primary">Kostenlos herunterladen</Button>
    </div>
  );

  return (
    <div className="App">
      
        <div className="checkout-component">
          <CartOverview cart={cart} isCheckout={true}/>
          {clientSecret && paymentId ? (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm user={user} paymentId={paymentId}/>
            </Elements>
          ) : (<Spinner/>)}
        </div>
    </div>
  );
}