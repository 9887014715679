import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { saveAs } from 'file-saver';
import './SuccessfulPaymentView.css'

const DigitalDownload: React.FC<{ paymentId: string, wasFree:boolean }> = ({ paymentId, wasFree }) => {

    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

    const apiUrl2 = process.env.REACT_APP_BackendApiBaseUrl + '/DigitalDownload';

    const download = async () => {
        setLoadingDownload(true)
        const response = await fetch(apiUrl2 + `?paymentId=${paymentId}`);
        const blob = await response.blob();
        saveAs(blob, 'archive.zip');
        setLoadingDownload(false)
    }

    return (
        <div className='successfullcomponent'>
            <h1>Vielen Dank 🥳</h1>
            {!wasFree && <p style={{ textAlign: 'center' }}>
                Sie haben digitale Downloads gekauft. Diese können Sie hier direkt herunterladen.<br />
                Sollten Sie sie später herunterladen wollen, verwenden Sie den Link in der Bestellbestätigung.<br />
                Alle anderen Produkte und Dienstleistungen werden schnellstmöglich versandt bzw. bearbeitet.
            </p>}
            <div className='successfullcomponent'>
                <p>Ihre digitalen Downloads stehen bereit!</p>
                <Button style={{ width: 300 }} onClick={download}>Download</Button>
                {loadingDownload && <Spinner />}
            </div>
            <img className='thank-you-img' src="/undraw_super_thank_you_re_f8bo.svg" alt="thank you"></img>
        </div>
    );
};

export default DigitalDownload;