import { toEuroString } from "../Util/PriceUtil";
import { Product } from '../Classes/Product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import './Shop.css';
import './ProductView.css';
import { Cart } from "../Classes/Cart";
import BuyButton from "./BuyButton";
import { useContext, useState } from "react";
import { ShopContext } from "../Classes/ShopContext";
import { OpenAPIClient } from "../Classes/ApiClient";

export const ProductView: React.FC<{ product: Product; cart:Cart, onProductDeleted:(p:Product)=>void }> = ({
  product,
  cart,
  onProductDeleted
}) => {

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  
  const context = useContext(ShopContext);
  const client = new OpenAPIClient();
  
  const deleteProduct = async () => {
    const shouldDelete = window.confirm(`Sicher, dass Sie ${product.name} löschen wollen?`);
    if(!shouldDelete) return;

    await client.deleteProduct(product.id, context?.user)
    onProductDeleted(product)
  }

  return (
    <Card className="product">
      <Card.Img onClick={toggleModal} className="product-image" height={300} variant="top" src={client.getThumbnailFor(product)} alt="product" />
      <Card.Body>
        <Card.Title style={{textOverflow:"ellipsis", overflow: 'hidden', whiteSpace: 'nowrap'}}>{product.name}</Card.Title>
        <Card.Text>
          {toEuroString(product.priceInCent)}{' '}
          {product.hasDigitalDownloads && <FontAwesomeIcon icon={faDownload} />}
        </Card.Text>
        <BuyButton cart={cart} product={product}/>
        {context?.user?.isAdmin && <Button style={{marginTop:3}} onClick={deleteProduct} variant="danger"><FontAwesomeIcon icon={faTrash} /> löschen</Button>}
      </Card.Body>
      <Modal show={showModal} onHide={toggleModal} size="xl">
        <ModalHeader closeButton>
          <ModalTitle style={{textOverflow:"ellipsis", overflow: 'hidden', whiteSpace: 'nowrap', textAlign:'center', paddingInline:20}} title={product.name}>
            {product.name}
          </ModalTitle>
        </ModalHeader>
        <ModalBody className="centered-flex">
          <img src={client.getThumbnailFor(product)} alt="Full Screen" className="img-fluid" />
        </ModalBody>
      </Modal>
    </Card>
  );
};