import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Shop from "./Components/Shop";
import SuccessfulPaymentView from "./Components/SuccessfulPaymentView";
import { Product } from "./Classes/Product";
import { useEffect, useState } from "react";
import { ConstructCartFrom, LoadCartFromStorage } from "./Classes/Cart";
import { CheckOutView } from "./Components/CheckoutView";
import { CollectionView } from "./Components/CollectionView";
import "./index.css";
import { User } from "./Classes/User";
import AuthProvider from "./Components/AuthProvider";
import SignInButton from "./Components/SignInButton";
import { Page } from "./Components/Page";
import './main.scss';
import { CartOverview } from "./Components/CartOverview";
import BatchCollectionCreation from "./Components/BatchCollectionCreation";
import { ShopContext } from "./Classes/ShopContext";
import DigitalDownloadPage from "./Components/DigitalDownloadPage";
import { Collection } from "./Classes/Collection";

export default function App() {

  const viewedKey = "viewedCollections";

  const getViewed : () => Collection[] = () => {
    const collections = sessionStorage.getItem(viewedKey)
    if(collections === null) return ([] as Collection[])

    return JSON.parse(collections) as Collection[]
  }

  const [cartItems, setCartItems] = useState<Product[]>([]);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [viewedCollections, setViewedCollections] = useState<Collection[]>(getViewed());

  useEffect(() => {
    LoadCartFromStorage(setCartItems)
  }, []);

  const addCollectionToViewed = (collectionId:Collection) => {
    const newViewed = [...viewedCollections, collectionId]
    setViewedCollections(newViewed)
    sessionStorage.setItem(viewedKey, JSON.stringify(newViewed))
    console.debug('viewed', newViewed)
  }

  const cart = ConstructCartFrom(cartItems, setCartItems)
  const ShopContextData = {
    cart: cart,
    user: user,
    viewedCollectionIds:viewedCollections,
    addCollectionToViewed:addCollectionToViewed
  }

  return (
    <ShopContext.Provider value={ShopContextData}>
      <AuthProvider setUser={setUser} user={user}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Page><Home /></Page>} />
            <Route path="shop" element={<Page><Shop /></Page>} />
            <Route path="cart" element={<Page><div className="centered"><CartOverview cart={cart} isCheckout={false} /></div></Page>} />
            <Route path="collections/:id" element={<Page><CollectionView /></Page>} />
            <Route path="collections/createBatch" element={<Page><BatchCollectionCreation user={user} /></Page>} />
            <Route path="checkout" element={<Page><CheckOutView /></Page>} />
            <Route path="success" element={<Page><SuccessfulPaymentView /></Page>} />
            <Route path="/downloads" element={<Page><DigitalDownloadPage/></Page>}/>
            <Route path="login" element={<Page><SignInButton user={user} setUser={setUser} /></Page>} />
            <Route path="*" element={<h1>404</h1>} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ShopContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root') as any);
root.render(<App />);
