import { Link } from 'react-router-dom';
import './Shop.css';
import './Home.css'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

const Home: React.FC = () => {
    return (
        <div className='home-component'>
            <h1>Willkommen bei {process.env.REACT_APP_ShopName}</h1>
            <img className='home-img' src="/undraw_explore_re_8l4v.svg" alt="Welcome"></img>
            <Link to="/shop">
                <Button style={{width:300,fontSize: 'larger',fontWeight: 'bold'}} variant='primary'>Los geht's! <FontAwesomeIcon icon={faPlay} /></Button>
            </Link>
        </div>
    );
};

export default Home;