import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './NavBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faUser, faHome, faShop } from '@fortawesome/free-solid-svg-icons';
import { User } from '../Classes/User';
import NumberBadge from './NumberBadge';
import { Cart } from '../Classes/Cart';

export const NavBar: React.FC<{user:User|undefined,cart:Cart}> = ({user,cart}) => {
  return (
    <Navbar expand="lg" className="navbar">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-nav">
          <Nav.Link href="/" as="a" className="nav-link"><FontAwesomeIcon icon={faHome} style={{ fontSize: '2rem' }}/></Nav.Link>
          <Nav.Link href="/shop" as="a" className="nav-link"><FontAwesomeIcon icon={faShop} style={{ fontSize: '2rem' }} /></Nav.Link>
          <Nav.Link href="/cart" as="a" className="nav-link"><NumberBadge icon={faShoppingCart} style={{ fontSize: '2rem' }} number={cart.items.length} /></Nav.Link>
          <Nav.Link href="/login" as="a" className="nav-link">{user !== undefined && user.name}<FontAwesomeIcon icon={faUser} style={{ fontSize: '2rem' }} /></Nav.Link>
          <h1 style={{color:"red"}}>This is only a test/development site!</h1>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
