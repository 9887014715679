import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { User } from '../Classes/User';
import { Button } from 'react-bootstrap';
import './CollectionCreation.css';
import { Collection } from '../Classes/Collection';
import { OpenAPIClient } from '../Classes/ApiClient';

const CollectionCreation: React.FC<{ user: User | undefined, onCollectionCreated:(collections:Collection)=>void }> = ({ user, onCollectionCreated }) => {

    const [collectionName, setCollectionName] = useState('');
    const [isPublic, setIsPublic] = useState(true);

    const client = new OpenAPIClient();
    const navigate = useNavigate();

    if (user === undefined) return <p>Loading Auth...</p>

    if (!user.isAdmin) return <Navigate to="/login" />;

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPublic(event.target.checked);
    };      

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCollectionName(event.target.value);
    };

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (collectionName.trim() !== '') {
            try {
                const newCollection = await client.createCollection({name:collectionName, isPublic:isPublic}, user)
                console.log(newCollection)
                onCollectionCreated(newCollection);
                setCollectionName('');
                navigate('/shop');
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        }
    };

    return (
        <form className="create-collection-form" onSubmit={handleFormSubmit}>
          <Link to="/collections/createBatch">
            <Button variant="primary">Gruppe mit Produkten erstellen</Button>
          </Link>
          
          <p style={{textAlign:'center', marginBlock:'20px'}}>oder direkt</p>

          <input
            style={{ width: '100%', marginBottom: 10 }}
            type="text"
            placeholder="Gruppenname"
            required
            value={collectionName}
            onChange={handleInputChange}
          />
          <label>
            <input
              type="checkbox"
              checked={isPublic}
              onChange={handleCheckboxChange}
            />
            Öffentlich sichbar?
          </label>
          <Button style={{ width: '100%' }} type="submit" variant="primary">
            Gruppe erstellen
          </Button>
        </form>
      );
      
};

export default CollectionCreation;
