import { cartItemKey } from "./Cart";

export interface User {
    isAdmin : boolean,
    name: string | undefined,
    email: string| undefined,
    shopToken:string|undefined
}

export const NotLoggedInUser = { isAdmin: false, name: undefined, email: undefined, shopToken: undefined }

export const clearAuthStorage = () => {
    let toberemoved = []
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if(key !== null && key !== cartItemKey){
            toberemoved.push(key)
        }
    }
    
    toberemoved.forEach(key => localStorage.removeItem(key))
}