import { Link } from "react-router-dom";
import { Cart } from "../Classes/Cart";
import { toEuroString } from "../Util/PriceUtil";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faDumpster, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './CartOverview.css'
import { Product } from "../Classes/Product";

export const CartOverview: React.FC<{
  cart: Cart;
  isCheckout: null | boolean;
}> = ({ cart, isCheckout }) => {

  const summedPriceInCent = cart.items.reduce((partialSum, a) => partialSum + a.priceInCent, 0);
  
  const getUniqueItemsWithFrequency = (items: Product[]) => {
    const frequencyMap = new Map<string, number>();
  
    for (const item of items) {
      const frequency = frequencyMap.get(item.id) || 0;
      frequencyMap.set(item.id, frequency + 1);
    }
  
    const uniqueItemsWithFrequency: { item: Product; frequency: number }[] = [];
  
    frequencyMap.forEach((frequency, id) => {
      const item = items.find((item) => item.id === id);
      if (item) {
        uniqueItemsWithFrequency.push({ item, frequency });
      }
    });
  
    return uniqueItemsWithFrequency;
  }

  const distinctProducts = getUniqueItemsWithFrequency(cart.items);

  const tooltipDelete = (
    <Tooltip id="tooltipDelete">
      Ein Element aus dem Warenkorb nehmen.
    </Tooltip>
  );

  const tooltipDeleteAll = (
    <Tooltip id="tooltipDeleteAll">
      Alle dieser Elemente aus dem Warenkorb nehmen.
    </Tooltip>
  );

  return (
    <div className="cart card">
      <h2>Einkaufswagen <FontAwesomeIcon icon={faShoppingCart} /></h2>
      <div className="cart-items">
        {cart.items.length === 0 ? (
          <div className="center">
            <img className='empty-cart-img' src="/undraw_empty_cart_co35.svg" alt="emtpy cart"></img>
            <p>Der Einkaufswagen ist leer</p>
          </div>
        ) : (
          <div>
            <Table striped bordered responsive>
              <thead>
                <tr>
                  <th>Produkt</th>
                  <th>Kosten</th>
                  {!isCheckout && <th className="text-center"></th>}
                </tr>
              </thead>
              <tbody>
                {distinctProducts.map((group, index) => (
                  <tr key={index}>
                    <td style={{ minWidth: "200px" }}>
                      {group.frequency}x {group.item.name} {group.item.hasDigitalDownloads && <FontAwesomeIcon icon={faDownload} />}
                    </td>
                    <td>{toEuroString(group.item.priceInCent)}</td>
                    {!isCheckout && (
                      <td style={{display:'flex', flexDirection:'row', gap:3}} className="text-center">
                        <OverlayTrigger placement="top" overlay={tooltipDelete} delay={500}>
                          <Button variant="secondary" size="sm" onClick={() => cart.removeFromCartCallback(group.item)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={tooltipDeleteAll} delay={500}>
                          <Button variant="secondary" size="sm" onClick={() => cart.removeAllSameFromCartCallback(group.item)}>
                            <FontAwesomeIcon icon={faDumpster} />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            <p><b>Insgesamt {toEuroString(summedPriceInCent)}</b></p>
          </div>
        )}
      </div>
      {!isCheckout && cart.items.length > 0 && (
        <Link to="/checkout">
          <Button variant="primary">Jetzt bezahlen</Button>
        </Link>
      )}
    </div>
  );
};
