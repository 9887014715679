import { useContext, useEffect, useState } from 'react';
import './Shop.css';
import { FailedPaymentView } from './FailedPaymentView';
import { ShopContext } from '../Classes/ShopContext';
import { Product } from '../Classes/Product';
import './SuccessfulPaymentView.css'
import DigitalDownload from './DigitalDownload';

const SuccessfulPaymentView: React.FC = () => {
  const [payedItems, setPayedItems] = useState<Product[]|undefined>(undefined);

  const queryParams = new URLSearchParams(window.location.search);
  const paymentFailed = (queryParams.get('redirect_status') ?? 'failed') === 'failed';

  const context = useContext(ShopContext);

  const paymentId = new URLSearchParams(window.location.search).get(
    "paymentId"
  );

  const digitalDownloads = payedItems?.filter(p => p.hasDigitalDownloads) ?? [];

  useEffect(()=>{
    if(paymentFailed) return;

    const cartLoaded = (context?.cart.items.length ?? 0) > 0
    if(payedItems === undefined && cartLoaded){
      console.debug(context)
      const bought = [...(context?.cart.items ?? [])]
      console.debug('bought', bought)
      setPayedItems(bought);
      context?.cart.clear();
    }
  }, [payedItems, paymentFailed, context]);

  if(paymentFailed) return (<FailedPaymentView/>)
  
  if(digitalDownloads.length > 0 && paymentId != null){
    return (<DigitalDownload wasFree={false} paymentId={paymentId}/>);
  }

  return (
  <div className='successfullcomponent'>
    <h1>Vielen Dank 🥳</h1>
    <p style={{textAlign:'center'}}>Ihre Bestellung wird nun bearbeitet.<br/>Sie können das Fenster schließen.</p>
    <img className='thank-you-img' src="/undraw_super_thank_you_re_f8bo.svg" alt="thank you"></img>
  </div>
  );
};

export default SuccessfulPaymentView;