import './SuccessfulPaymentView.css'
import DigitalDownload from "./DigitalDownload";
import { Navigate } from "react-router-dom";

const DigitalDownloadPage: React.FC = () => {

    const paymentId = new URLSearchParams(window.location.search).get(
        "paymentId"
    );

    function parseBoolean(value?: string | number | boolean | null) {
        value = value?.toString().toLowerCase();
        return value === 'true' || value === '1';
    }

    const wasFree = parseBoolean(new URLSearchParams(window.location.search).get(
        "free"
    ));

    if(paymentId === null) return (<Navigate to={'/'} />);

    return (<DigitalDownload wasFree={wasFree} paymentId={paymentId}/>);
};

export default DigitalDownloadPage;