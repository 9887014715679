import { Link } from 'react-router-dom';
import { Collection } from '../Classes/Collection';
import './Shop.css';
import './CollectionThumbnail.css';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faTrash, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { ShopContext } from '../Classes/ShopContext';
import { OpenAPIClient } from '../Classes/ApiClient';

export const CollectionThumbnail: React.FC<{ collection: Collection, onCollectionDeleted:(c:Collection) => void, isSharedCollection?:boolean }> = ({
  collection,
  onCollectionDeleted,
  isSharedCollection
}) => {

  const context = useContext(ShopContext);
  const client = new OpenAPIClient();

  const deleteCollection : React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()
    const shouldDelete = window.confirm(`Sicher, dass Sie ${collection.name} löschen wollen?`);
    if(!shouldDelete) return;

    await client.deleteCollection(collection.id, context!.user!)
    onCollectionDeleted(collection)
  }

  return (
    <Link className='collection-thumbnail-container' to={"/collections/" + collection.id + "?collectionName=" + collection.name} >
      <Card className="collection-thumbnail">
        <Card.Body>
          <Card.Title title={collection.name} style={{textOverflow:"ellipsis", overflow: 'hidden', whiteSpace: 'nowrap'}}>
            {!collection.isPublic && !isSharedCollection && <FontAwesomeIcon icon={faLock}/>}
            {isSharedCollection && <FontAwesomeIcon title='Diese Gruppe wurde mit Ihnen geteilt und ist temporär sichbar' icon={faUnlock}/>}
            {collection.name}
          </Card.Title>
          {context?.user?.isAdmin && !isSharedCollection && <Button style={{marginTop:3}} onClick={deleteCollection} variant="danger"><FontAwesomeIcon icon={faTrash} /> löschen</Button>}
        </Card.Body>
      </Card>
    </Link>
  );
};