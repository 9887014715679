import React, { useContext } from 'react';
import { NavBar } from './NavBar';
import './Page.css';
import { ShopContext } from '../Classes/ShopContext';

export const Page: React.FC<{children:any}> = ({children}) => {
  
  const context = useContext(ShopContext);

  if(context === undefined) return <h1>Error :/</h1>

  const { user, cart } = context;
  return (
    <div className='page'>
        <NavBar user={user} cart={cart}/>
        {children}
    </div>
  );
}