import { useContext, useEffect, useState } from 'react';
import { Product } from '../Classes/Product';
import { ProductView } from './ProductView';
import { Link, useParams } from 'react-router-dom';
import { CartOverview } from './CartOverview';
import './Shop.css';
import './CollectionView.css'
import ProductCreation from './ProductCreation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faShare, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner } from 'react-bootstrap';
import { ShopContext } from '../Classes/ShopContext';
import { OpenAPIClient } from '../Classes/ApiClient';

export const CollectionView: React.FC = () => {
  const { id } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const collectionName = queryParams.get('collectionName') ?? 'Gruppe';
  const [products, setProducts] = useState<Product[]|undefined>(undefined);
  const context = useContext(ShopContext);

  const client = new OpenAPIClient()

  const generateMailToLink = () => {
    const text = `Guten Tag,\n\n${window.location.href}\n\nViele liebe Grüße,\n`
    return `mailto:?subject=${encodeURI(collectionName)}&body=${encodeURI(text)}`
  }

  const fetchProducts = async () => {
    if (id === undefined) return;

    try {
      const returnedProducts = await client.getProducts(id)
      setProducts(returnedProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleByAllClick = () => {
    if(products === undefined) return;

    const addable = products.filter(p => !p.hasDigitalDownloads || cart.items.findIndex(p2 => p2.id === p.id) === -1)
    cart.addAllToCartCallback(addable)
  }

  const onProductDeleted = (p:Product)=>{
    setProducts(undefined);
  }

  useEffect(()=>{
    if(context === undefined || id === undefined) return;
    if(context.viewedCollectionIds.every(cid => cid.id !== id)){
      context.addCollectionToViewed({id:id, isPublic:false, name:collectionName})
    }
  }, [context, id, collectionName])

  useEffect(() => {
    if(products !== undefined) return;
    fetchProducts();
  });

  if(context === undefined) return <h1>Error :/</h1>
  const { user, cart } = context;

  if(products === undefined || id === undefined) return (<h2>Läd Produkte... <Spinner/></h2>);

  return (
    <div className="collection-view">
      <h1><Link to={'/shop'} style={{fontSize:25}}><FontAwesomeIcon icon={faArrowLeft} /></Link> {collectionName}</h1>
      <Button variant='primary' disabled={products.length === 0} style={{width:300, marginTop:30}} onClick={handleByAllClick}>
        <FontAwesomeIcon icon={faShoppingCart} />Alles in den Warenkorb
      </Button>
      <a style={{marginTop:15}} href={generateMailToLink()}>Teilen <FontAwesomeIcon icon={faShare}/></a>
      <div className="products">
        {products.length === 0 && <p>No Products</p>}
        {products.map((product) => (
          <ProductView
            key={"product" + product.id}
            product={product}
            cart={cart}
            onProductDeleted={onProductDeleted}
          />
        ))}
      </div>
      {user?.isAdmin && <ProductCreation collectionId={id} user={user}/>}
      <CartOverview cart={cart} isCheckout={false} />
    </div>
  );
};