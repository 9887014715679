import { useContext, useEffect, useState } from "react";
import { Collection } from "../Classes/Collection";
import { CollectionThumbnail } from "./CollectionThumbnail";
import './Shop.css';
import './CollectionOverview.css'
import { User } from "../Classes/User";
import CollectionCreation from "./CollectionCreation";
import { Spinner } from "react-bootstrap";
import { OpenAPIClient } from "../Classes/ApiClient";
import { ShopContext } from "../Classes/ShopContext";

export const CollectionOverview: React.FC<{user:User|undefined}> = ({user}) => {

    const [collections, setCollections] = useState<Collection[]|undefined>(undefined);
    const [fetchedFor, setFetchedFor] =  useState<User|undefined>(undefined);
    const context = useContext(ShopContext);

    const getSharedCollections: ()=>Collection[] = () => {
        if(context === undefined || collections === undefined) return []

        return context.viewedCollectionIds.filter(c => collections.every(c2 => c.id !== c2.id))
    }

    const onCollectionDeleted = (c:Collection) => {
        setCollections(undefined)
    }

    const onCollectionCreated = (c:Collection) => {
        setCollections([...collections!, c])
    }

    useEffect(() => {
        if(collections !== undefined && fetchedFor === user) return;

        const client = new OpenAPIClient();
        const fetchCollections = async (currentUser : User|undefined) => {
            try {
                const data = await client.getCollections(currentUser);
                console.debug(data)
                setFetchedFor(currentUser)
                setCollections(data);
            } catch (error) {
                console.error('Error fetching collections:', error);
            }
        };
        console.debug("fetching with", user)
        fetchCollections(user);
    }, [user, collections, fetchedFor]);

    if(collections === undefined) return (<h2>Läd Gruppen... <Spinner/></h2>)

    return (
        <div className="collection-overview-body">
            <div className="collection-list">
                {collections.map((collection) => (
                    <CollectionThumbnail
                        key={"collection" + collection.id}
                        collection={collection}
                        onCollectionDeleted={onCollectionDeleted}
                    />
                ))}
                {getSharedCollections().map((collection) => (
                    <CollectionThumbnail
                        key={"collection" + collection.id}
                        collection={collection}
                        onCollectionDeleted={onCollectionDeleted}
                        isSharedCollection={true}
                    />
                ))}
            </div>
            {user?.isAdmin && <CollectionCreation user={user} onCollectionCreated={onCollectionCreated}/>}
        </div>
    );
};