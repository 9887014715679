import React, { useState } from 'react';
import { User } from '../Classes/User';
import './ProductCreation.css';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { OpenAPIClient } from '../Classes/ApiClient';

interface ProductCreationProps {
  collectionId: string;
  user: User;
}

const ProductCreation: React.FC<ProductCreationProps> = ({ collectionId, user }) => {
  const [productName, setProductName] = useState('');
  const [cent, setCent] = useState('00');
  const [euro, setEuro] = useState('');
  const [priceInCent, setPriceInCent] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [watermarked, setWatermarked] = useState(false);
  const [hasDigitalDownload, setHasDigitalDownload] = useState(false);

  const client = new OpenAPIClient();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox' && name === 'watermarked') {
      setWatermarked(checked);
    } else if (type === 'checkbox' && name === 'hasDigitalDownload') {
      setHasDigitalDownload(checked);
    } else if (name === 'productName') {
      setProductName(value);
    } else if (name === 'image') {
      const file = event.target.files?.[0] || null;
      setImageFile(file);
    } else if (name === 'cent') {
      setCent(value);
      setPriceInCent(((+value) + (+euro) * 100).toString())
    } else if (name === 'euro') {
      setEuro(value);
      setPriceInCent(((+cent) + (+value) * 100).toString())
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (productName.trim() !== '' && priceInCent.trim() !== '' && imageFile) {
      try {
          await client.createProducts(
            [{
              hasDigitalDownload:hasDigitalDownload,
              imageFile:imageFile,
              priceInCent:priceInCent,
              productName:productName,
              watermarked:watermarked
            }],
            collectionId,
            user
          )
          
          setProductName('');
          setPriceInCent('');
          setImageFile(null);
          setWatermarked(false);
          window.location.reload();
      } catch (error) {
        console.error('Error creating product:', error);
      }
    }
  };

  return (
    <div className="product-creation">
      <Form className="product-creation-form" onSubmit={handleFormSubmit}>
        <Form.Control
          type="text"
          name="productName"
          placeholder="Enter product name"
          value={productName}
          onChange={handleInputChange}
        />
        <InputGroup className="mb-3">
          <Form.Control
            type="number"
            min="0"
            placeholder="Euros"
            name="euro"
            value={euro}
            onChange={handleInputChange}
          />
          <InputGroup.Text>€</InputGroup.Text>
          <Form.Control
            type="number"
            min="0"
            max="99"
            name="cent"
            placeholder="Cents"
            value={cent}
            onChange={handleInputChange}
          />
          <InputGroup.Text>ct</InputGroup.Text>
        </InputGroup>
        <Form.Control
          type="file"
          name="image"
          onChange={handleInputChange}
        />
        <Form.Check
          type="checkbox"
          name="watermarked"
          label="Watermarked"
          checked={watermarked}
          onChange={handleInputChange}
        />
        <Form.Check
          type="checkbox"
          name="hasDigitalDownload"
          label="Digital download"
          checked={hasDigitalDownload}
          onChange={handleInputChange}
        />
        <Button type="submit" variant="primary">
          Create Product
        </Button>
      </Form>
    </div>
  );
};

export default ProductCreation;