export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AppClientID!,
        authority: 'https://login.microsoftonline.com/bfa98759-cf17-4f76-a45b-7f0474af636f',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
};