import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './NumberBadge.css';

interface NumberBadgeProps {
  icon: IconProp;
  number: number;
  style?: React.CSSProperties;
}

const NumberBadge: React.FC<NumberBadgeProps> = ({ icon, number, style }) => {
  return (
    <div className="number-badge" style={style}>
      <FontAwesomeIcon icon={icon} />
      <span className="my-badge">{number}</span>
    </div>
  );
};

export default NumberBadge;
