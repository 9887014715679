import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import './FailedPaymentView.css'

export const FailedPaymentView: React.FC = () => {


    return (
    <div className="failedpayment-container">
        <h3>Leider hat etwas bei dem Bezahlen nicht geklappt 😥</h3>
        <Link to={'/checkout'}>
        <Button>Erneut versuchen</Button>
        </Link>
    </div>
    )
}