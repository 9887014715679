import React, { useContext } from 'react';
import './Shop.css';
import { CartOverview } from './CartOverview';
import { CollectionOverview } from './CollectionOverview';
import { ShopContext } from '../Classes/ShopContext';

const Shop: React.FC = () => {

  const context = useContext(ShopContext);
  if(context === undefined) return <h1>Error :/</h1>
  const { user, cart } = context;
  
  return (
      <div className="shop">
        <h1>{process.env.REACT_APP_ShopName}</h1>
        <CollectionOverview user={user}/>
        <CartOverview cart={cart} isCheckout={false}/>
      </div>
  );
};

export default Shop;