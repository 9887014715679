import { Collection } from "./Collection";
import { Product } from "./Product";
import { User } from "./User";

interface ProductCreationRequest {
    collectionId: string;
    name: string;
    priceInCent: number;
    hasDigitalDownloads: boolean;
    base64SourceImage?: string;
    isWatermarked?: boolean;
}

export interface ProductCreationData {
    imageFile: File,
    productName: string,
    priceInCent: string,
    watermarked: boolean,
    hasDigitalDownload: boolean
}

export interface CollectionCreationData {
    name : string,
    isPublic: boolean
}

export class OpenAPIClient {
    
    private baseUrl: string;
    private storageUrl: string;

    constructor() {
        this.baseUrl = process.env.REACT_APP_BackendApiBaseUrl!
        this.storageUrl = process.env.REACT_APP_BackendStorageUrl!
    }

    public async getProducts(collectionId: string): Promise<Product[]> {
        const url = `${this.baseUrl}/Products?collectionId=${encodeURIComponent(collectionId)}`;
        const response = await fetch(url, {mode:'cors'});
        const data = await response.json();
        if (response.ok) {
            return data as Product[];
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    }

    public async deleteCollection(collectionId: string, user: User) {
        const url = `${this.baseUrl}/DeleteCollection?collectionId=${encodeURIComponent(collectionId)}`;
        var headers = user === undefined ? undefined : {Authorization: `Bearer ${user.shopToken}`}
        const response = await fetch(url, {headers:headers, method:'delete', mode:'cors'});
        if (response.ok) {
            return true;
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    }

    public async deleteProduct(productId: string, user:User|undefined): Promise<boolean> {
        const url = `${this.baseUrl}/DeleteProduct?productId=${encodeURIComponent(productId)}`;
        var headers = user === undefined ? undefined : {Authorization: `Bearer ${user.shopToken}`}
        const response = await fetch(url, {headers:headers, method:'delete', mode:'cors'});
        if (response.ok) {
            return true;
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    }

    public async getCollections(user:User|undefined): Promise<Collection[]> {
        const url = `${this.baseUrl}/Collections`;
        var headers = user === undefined ? undefined : {Authorization: `Bearer ${user.shopToken}`}

        const response = await fetch(url, {headers:headers, mode:'cors'});
        const data = await response.json();
        if (response.ok) {
            return data as Collection[];
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    }

    public async createCollection(collectionCreationData: CollectionCreationData, user:User|undefined): Promise<Collection> {
        const url = `${this.baseUrl}/CreateCollection`;
        var headers = user === undefined ? undefined : {"Content-Type": "application/json", Authorization: `Bearer ${user.shopToken}`}

        const response = await fetch(url, {headers:headers, method:'POST', body:JSON.stringify(collectionCreationData), mode:'cors'});
        const data = await response.json();
        if (response.ok) {
            return data as Collection;
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    }

    public getThumbnailFor(product:Product) : string {
        return `${this.storageUrl}/thumbnails/th-${product.id}.jpg`
    }

    public async createProducts(products: ProductCreationData[], collectionId: string, user: User): Promise<Product[]> {
        const url = `${this.baseUrl}/CreateProducts`;
        console.debug("creating...")
        const requests: Promise<ProductCreationRequest>[] = products.map(data => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const imageBase64 = reader.result?.toString() || '';
                    const payload: ProductCreationRequest = {
                        collectionId: collectionId,
                        name: data.productName,
                        priceInCent: parseInt(data.priceInCent),
                        hasDigitalDownloads: data.hasDigitalDownload,
                        base64SourceImage: imageBase64,
                        isWatermarked: data.watermarked,
                    };
                    console.debug("mapped payload")
                    resolve(payload);
                };
                reader.onerror = reject;
                reader.readAsDataURL(data.imageFile);
            });
        });

        var response = await Promise.all(requests)
            .then(async (payloads) => {
                console.debug("sending creation request")
                const result = await fetch(url,
                    {
                        body: JSON.stringify(payloads),
                        method: 'POST',
                        headers: { 
                            Authorization: `Bearer ${user.shopToken}`,
                            "Content-Type": "application/json",
                        },
                        mode:'cors'
                    }
                );
            if(!result.ok) throw await result.text

            return await result.json() as Product[]
        })
        .catch((error) => {
            console.error('Failed to process requests:', error);
            return [] as Product[]
        });

        return response;
    }
}
