import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../Classes/msalConfig';
import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useEffect, useMemo } from 'react';
import { NotLoggedInUser, User, clearAuthStorage } from '../Classes/User';

const AuthProvider: React.FC<{ children: any, setUser: (accounts: User) => void, user: User | undefined }> = ({ children, setUser, user }) => {

  const pca = useMemo(() => new PublicClientApplication(msalConfig), []);

  useEffect(() => {
    console.debug('triggered authcheck')
  
    if (pca === null) {
        setUser(NotLoggedInUser);
        return;
    }

    const fetchData = async () => {
      try {
        const accounts = pca.getAllAccounts();
        console.debug('currentUser', user, 'Accounts', accounts);
        if (user !== undefined && user.isAdmin === (accounts.length > 0)) return;

        if (accounts.length > 0) {
          const userAccount = accounts[0];
          const userName = userAccount.name;

          try {
            const request = {
              scopes: [process.env.REACT_APP_ShopAdministractAPI!],
              account: userAccount,
            };

            const response = await pca.acquireTokenSilent(request);
            const token = response?.accessToken;

            let newUser = { isAdmin: true, name: userName, email: userAccount.username, shopToken: token };
            console.debug('User:', newUser);
            setUser(newUser);
          } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
              console.debug('silent sign in failed - token expired')
              clearAuthStorage()
              setUser(NotLoggedInUser);
            } else {
              console.error('other failed')
              // Handle other types of errors
            }
          }
        } else {
          let newUser = NotLoggedInUser;
          console.debug('User:', newUser);
          setUser(newUser);
        }
      } catch (error) {
        // Handle getAllAccounts error
      }
    };

    fetchData();
  }, [pca, setUser, user]);

  if (pca === null) return <div>{children}</div>;

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};

export default AuthProvider;