import { Product } from "./Product";

export interface Cart {
    addToCartCallback : (product: Product) => void
    addAllToCartCallback : (products: Product[]) => void
    removeFromCartCallback : (product: Product) => void
    removeAllSameFromCartCallback: (product: Product) => void
    clear: () => void
    items : Product[]
}

const addAllToCart = (cartItems:Product[], setCartItems:(p:Product[]) => void) => (products: Product[]) => {
    const newItems = [...cartItems, ...products].sort((a, b) => a.name.localeCompare(b.name))
    localStorage.setItem(cartItemKey, JSON.stringify(newItems))
    setCartItems(newItems);
};

const removeFromCart = (cartItems:Product[], setCartItems:(p:Product[]) => void) => (toBeRemovedProduct: Product) => {
    let index = cartItems.findIndex(product => product.id === toBeRemovedProduct.id);
    console.debug('deleting', toBeRemovedProduct, 'from',  cartItems,'at index', index)
    const updatedCart = [...cartItems]
    updatedCart.splice(index, 1)
    console.debug('now', updatedCart)
    localStorage.setItem(cartItemKey, JSON.stringify(updatedCart))
    setCartItems(updatedCart);
};

const removeAllFromCart = (cartItems:Product[], setCartItems:(p:Product[]) => void) => (toBeRemovedProduct: Product) => {
    const updatedCart = cartItems.filter(product => product.id !== toBeRemovedProduct.id);
    localStorage.setItem(cartItemKey, JSON.stringify(updatedCart))
    setCartItems(updatedCart);
};

export const LoadCartFromStorage = (setCartItems:(p:Product[]) => void) => {
    var cartString = localStorage.getItem(cartItemKey)
    if(cartString === null) return;
    setCartItems(JSON.parse(cartString))
}

export const ConstructCartFrom = (cartItems:Product[], setCartItems:(p:Product[]) => void) : Cart => {
    return { 
        addToCartCallback: (p:Product) => addAllToCart(cartItems, setCartItems)([p]),
        addAllToCartCallback: addAllToCart(cartItems, setCartItems),
        removeFromCartCallback: removeFromCart(cartItems, setCartItems),
        removeAllSameFromCartCallback: removeAllFromCart(cartItems, setCartItems),
        clear: () => {setCartItems([]); localStorage.removeItem(cartItemKey)},
        items: cartItems 
    }
}


export const cartItemKey = 'cartItemKey'