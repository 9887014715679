import React from 'react';
import { Cart } from './Cart';
import { User } from './User';
import { Collection } from './Collection';

export interface IShopContext {
    cart:Cart,
    user:User|undefined,
    viewedCollectionIds:Collection[],
    addCollectionToViewed:(collectionId:Collection)=>void
}

export const ShopContext = React.createContext<IShopContext|undefined>(undefined);