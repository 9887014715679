import React, { useState, ChangeEvent, FormEvent } from 'react';
import { User } from '../Classes/User';
import { Button, Card, Form, InputGroup, Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { Collection } from '../Classes/Collection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { OpenAPIClient } from '../Classes/ApiClient';

interface ProductStub {
    name: string;
    file: File;
    preview: string;
}

const BatchCollectionCreation: React.FC<{ user: User | undefined }> = ({ user }) => {

    const [collectionName, setCollectionName] = useState('');
    const [isPublic, setIsPublic] = useState(true);
    const [productNamePrefix, setProductNamePrefix] = useState('');
    const [price, setPrice] = useState(0);
    const [cent, setCent] = useState('00');
    const [euro, setEuro] = useState('');
    const [digitalDownload, setDigitalDownload] = useState(false);
    const [products, setProducts] = useState<ProductStub[]>([]);
    const [watermark, setWatermark] = useState(false);

    const client = new OpenAPIClient();
    const [createdCollection, setCreatedCollection] = useState<Collection | undefined>(undefined);
    const [createdProducts, setCreatedProducts] = useState<ProductStub[]>([]);
    const [done, setDone] = useState(false);

    if (user === undefined) return (<h2>Läd Nutzer... <Spinner /></h2>)
    if (!user.isAdmin) return (<Navigate to={'/Login'} />)
    if (done && createdCollection !== undefined) return (<Navigate to={'/collections/' + encodeURI(createdCollection?.id)} />);

    const handleCollectionNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCollectionName(e.target.value);
    };

    const handleProductNamePrefixChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProductNamePrefix(e.target.value);
    };

    const handleIsPublicChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsPublic(e.target.checked);
    };

    const handleWatermarkChange = (e: ChangeEvent<HTMLInputElement>) => {
        setWatermark(e.target.checked);
    };

    const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'cent') {
            setCent(value);
            setPrice(((+value) + (+euro) * 100))
        } else if (name === 'euro') {
            setEuro(value);
            setPrice(((+cent) + (+value) * 100))
        }
    };

    const handleDigitalDownloadChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDigitalDownload(e.target.checked);
    };

    const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedImages = Array.from(e.target.files as FileList);
        const newProducts: ProductStub[] = selectedImages.map((file) => ({
            name: file.name,
            file,
            preview: URL.createObjectURL(file),
        }));
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
    };

    const handleProductNameChange = (
        e: ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const { value } = e.target;
        setProducts((prevProducts) =>
            prevProducts.map((product, i) =>
                i === index ? { ...product, name: value } : product
            )
        );
    };

    const delay = (ms: number) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    };

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();

        client.createCollection({ name: collectionName, isPublic: isPublic }, user).then(
            async (newCollection) => {
                setCreatedCollection(newCollection)
                const productCreationRequests = products.map(element => {
                    return {
                        productName: productNamePrefix + element.name,
                        imageFile: element.file,
                        hasDigitalDownload: digitalDownload,
                        watermarked: watermark,
                        priceInCent: price.toString(),
                    }
                });
                let created: ProductStub[] = []
                for (let i = 0; i < productCreationRequests.length; i++) {
                    try {
                        const req = productCreationRequests[i];
                        const newCreatedProducts = await client.createProducts([req], newCollection.id, user)
                        created = created.concat(products.filter(p => newCreatedProducts.some(p2 => p2.name === p.name)))
                        setCreatedProducts(created)
                    } catch (error) {
                        console.error(error)
                    }
                }
            }
        ).then(
            async () => await delay(3000)
        ).then(
            () => setDone(true)
        ).catch(e => console.error(e));
    };

    return (
        <div className="container">
            <h2>Batch Collection Creation</h2>
            <form onSubmit={handleFormSubmit}>
                <Card>
                    <Card.Body>
                        <Card.Title>Gruppeneigenschaften</Card.Title>
                        <div className="form-group">
                            <label>Gruppenname</label>
                            <input
                                disabled={createdCollection !== undefined}
                                type="text"
                                className="form-control"
                                value={collectionName}
                                onChange={handleCollectionNameChange}
                                required
                            />
                        </div>
                        <div className="form-group form-check">
                            <input
                                disabled={createdCollection !== undefined}
                                type="checkbox"
                                className="form-check-input"
                                checked={isPublic}
                                onChange={handleIsPublicChange}
                            />
                            <label className="form-check-label">Öffentlich sichtbar?</label>
                        </div>
                    </Card.Body>
                </Card>
                <br />
                <Card>
                    <Card.Body>
                        <Card.Title>Produkteigenschaften</Card.Title>
                        <div className="form-group">
                            <label>Productname-Prefix</label>
                            <input
                                disabled={createdCollection !== undefined}
                                type="text"
                                className="form-control"
                                value={productNamePrefix}
                                onChange={handleProductNamePrefixChange}
                            />
                        </div>
                        <label>Preis</label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                disabled={createdCollection !== undefined}
                                type="number"
                                min="0"
                                placeholder="Euros"
                                name="euro"
                                value={euro}
                                required
                                onChange={handlePriceChange}
                            />
                            <InputGroup.Text>€</InputGroup.Text>
                            <Form.Control
                                disabled={createdCollection !== undefined}
                                type="number"
                                min="0"
                                max="99"
                                name="cent"
                                placeholder="Cents"
                                value={cent}
                                required
                                onChange={handlePriceChange}
                            />
                            <InputGroup.Text>ct</InputGroup.Text>
                        </InputGroup>
                        <div className="form-group form-check">
                            <input
                                disabled={createdCollection !== undefined}
                                type="checkbox"
                                className="form-check-input"
                                checked={digitalDownload}
                                onChange={handleDigitalDownloadChange}
                            />
                            <label className="form-check-label">Digitaler Download?</label>
                        </div>
                        <div className="form-group form-check">
                            <input
                                disabled={createdCollection !== undefined}
                                type="checkbox"
                                className="form-check-input"
                                checked={watermark}
                                onChange={handleWatermarkChange}
                            />
                            <label className="form-check-label">Wasserzeichen?</label>
                        </div>
                        <div className="form-group">
                            <label>Bilder hochladen</label>
                            <input
                                disabled={createdCollection !== undefined}
                                type="file"
                                className="form-control-file"
                                accept="image/*"
                                multiple
                                onChange={handleImageUpload}
                                required
                            />
                        </div>
                    </Card.Body>
                </Card>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Bild</th>
                            <th>Produktname</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => (
                            <tr key={index}>
                                <td>
                                    <img src={product.preview} alt={product.name} width="50" />
                                </td>
                                <td style={{display:'flex',flexDirection:'row'}}>
                                    {productNamePrefix}
                                    <input
                                        disabled={createdCollection !== undefined}
                                        type="text"
                                        className="form-control"
                                        value={product.name}
                                        required
                                        onChange={(e) => handleProductNameChange(e, index)}
                                    />
                                </td>
                                <td>
                                    {createdCollection !== undefined && !createdProducts.includes(product) && <Spinner />}
                                    {createdProducts.includes(product) && <FontAwesomeIcon icon={faCircleCheck} />}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Button type="submit" disabled={createdCollection !== undefined} variant='primary'>
                    Gruppe erstellen
                </Button>
            </form>
        </div>
    );
};

export default BatchCollectionCreation;