import { useMsal } from '@azure/msal-react';
import { Button, Spinner } from 'react-bootstrap';
import './SignInButton.css'
import { useNavigate } from 'react-router-dom';
import { NotLoggedInUser, User, clearAuthStorage } from '../Classes/User';

const SignInButton: React.FC<{user:User|undefined, setUser: (accounts: User|undefined) => void}> = ({user,setUser}) => {
    const { instance } = useMsal();
    const navigate = useNavigate();

    const handleSignIn = async () => {
        const loginRequest = {
            scopes: ['user.read'],
        };

        try {
            await instance.loginPopup(loginRequest);
        } catch (error) {
            console.error(error)
        }

        setUser(undefined);
        navigate('/shop')
    };

    const handleSignOut = async () => {
        clearAuthStorage()
        setUser(NotLoggedInUser);
        navigate('/shop')
    };

    if(user === undefined){
        return (<h2>Läd Account-Status... <Spinner/></h2>)
    }

    if(user.isAdmin){
        return ( 
            <div className='signin-component'>
                <h3>Hallo {user.name} - Sie sind bereits eingeloggt</h3>
                <Button style={{width:200}} onClick={handleSignOut} variant="primary">Ausloggen</Button>
            </div>
        );
    }

    return ( 
        <div className='signin-component'>
            <h3>Loggen Sie sich hier ein wenn Sie ein Admin sind...</h3>
            <Button style={{width:200}} onClick={handleSignIn} variant="primary">Einloggen mit Microsoft</Button>
        </div>
    );
};

export default SignInButton;
