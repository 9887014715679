import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleInfo, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Product } from '../Classes/Product';
import { Cart } from '../Classes/Cart';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';


const BuyButton: React.FC<{cart:Cart, product:Product}> = ({ cart, product }) => {
  const [isAddedToCart, setIsAddedToCart] = useState(false);

  const handleAddToCart = () => {

    cart.addToCartCallback(product);
    setIsAddedToCart(true);

    setTimeout(() => {
      setIsAddedToCart(false);
    }, 1000);
  };

  const itemAlreadyInCart = cart.items.find(p => p.id === product.id) !== undefined;

  const tooltipContent = (
    <Tooltip id="tooltip">
      Digitale Downloads müssen nur einmal gekauft werden 😊
    </Tooltip>
  );

  return (
    <div style={{display:'flex', flexDirection:'row', gap:10}}>
        <Button onClick={handleAddToCart} disabled={itemAlreadyInCart && product.hasDigitalDownloads} variant="primary">
            <FontAwesomeIcon icon={faShoppingCart} /> kaufen
        </Button>
        {isAddedToCart && (
            <div className="feedback">
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
        )}
        {itemAlreadyInCart && !isAddedToCart && product.hasDigitalDownloads && <OverlayTrigger placement="top" overlay={tooltipContent}><FontAwesomeIcon icon={faCircleInfo} /></OverlayTrigger>}
    </div>
  );
};

export default BuyButton;
